import React from 'react';
import styled from 'styles/styled';

import { Tag } from 'components/Blog/BlogCard/styled';
import { H1, TransparentButton, Paragraph } from '../../../components';

export const Wrapper = styled.div`
  margin: 90px auto 130px;
`;

export const HeroBackground = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.primary};
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);

  @media ${({ theme }) => theme.devices.xlarge} {
    height: calc(100% + 130px);
    clip-path: polygon(0 0, 100% 0, 100% 76%, 0 52%);
  }
`;

export const HeroContainer = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.palette.light};
  text-align: center;
  padding-top: 0;
  min-height: 355px;

  @media ${({ theme }) => theme.devices.xlarge} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 130px;
    min-height: unset;
  }
`;

export const HeroTextWrapper = styled.div`
  z-index: 1;
  align-self: flex-start;

  @media ${({ theme }) => theme.devices.xlarge} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 50px;
  }
`;

export const TagsWrapper = styled.div``;

export const StyledTag = styled(Tag)`
  color: ${({ theme }) => theme.palette.light};

  &:not(&:last-of-type) {
    &:after {
      background-color: ${({ theme }) => theme.palette.light};
    }
  }
`;

export const TimeRead = styled(Paragraph)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.light};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-top: 4px;
`;

export const Title = styled(H1)`
  max-width: 500px;
  margin-top: 32px;
  color: ${({ theme }) => theme.palette.light};
`;

export const Content = styled.article`
  padding: 0 15px;
  margin: 28px auto 0;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  max-width: 880px;

  @media ${({ theme }) => theme.devices.xlarge} {
    margin: 104px auto 0;
  }

  p,
  li {
    //styleName: mobile/m_H5;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: left;

    margin-bottom: 25px;

    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 7px;
    }

    @media ${({ theme }) => theme.devices.large} {
      //styleName: desktop/H5;
      font-size: 15px;
      font-weight: 300;
      line-height: 22px;
      letter-spacing: 0px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    //styleName: mobile/m_H3;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;

    margin-bottom: 25px;

    @media ${({ theme }) => theme.devices.large} {
      //styleName: desktop/H3;
      font-family: Roboto;
      font-size: 22px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0px;

      margin-bottom: 32px;
    }
  }

  ul {
    padding-left: 2em;
    list-style: disc;
  }

  em,
  blockquote {
    font-style: italic;
  }

  figure {
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 100vw;

    > span {
      margin: 0 auto;
    }

    figcaption {
      display: none;
    }

    @media ${({ theme }) => theme.devices.medium} {
      margin: 50px auto;
      width: unset;
      max-width: 100%;
    }
  }

  a {
    color: ${({ theme }) => theme.palette.primary};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  // Source:
  // https://avexdesigns.com/blog/responsive-youtube-embed
  .wp-block-embed__wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }

  .wp-block-embed__wrapper iframe,
  .wp-block-embed__wrapper object,
  .wp-block-embed__wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .wp-block-embed {
    width: 100%;
  }
`;

export const BlogPostNavigationSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 420px;
  z-index: 111;

  @media ${({ theme }) => theme.devices.xlarge} {
    height: 370px;
  }
`;

export const BlogPostNavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  margin-top: 35px;
`;

export const BlogPostNavigationBackground = styled.div`
  z-index: -1;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.palette.primary};
  clip-path: polygon(0 35%, 100% 27%, 100% 100%, 0% 100%);

  @media ${({ theme }) => theme.devices.xlarge} {
    clip-path: polygon(0 33%, 100% 0, 100% 100%, 0% 100%);
  }
`;

export const BlogPostNavigationCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  margin: 0 15px;

  @media ${({ theme }) => theme.devices.xlarge} {
    width: unset;
    margin-left: 50px;
    justify-content: flex-start;
    align-items: baseline;
  }
`;

export const BlogPostNavigationUpNextButton = styled(TransparentButton)`
  color: ${({ theme }) => theme.palette.dark};
  align-self: flex-start;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-right: auto;
  margin-top: auto;
  margin-bottom: 24px;

  @media ${({ theme }) => theme.devices.xlarge} {
    color: ${({ theme }) => theme.palette.light};
    margin-bottom: 16px;
  }
`;

export const BlogPostNavigationTitle = styled((props) => (
  <H1 as="h2" {...props} />
))`
  color: ${({ theme }) => theme.palette.light};
  max-width: 700px;
  margin-top: 24px;
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.5px;
  z-index: 1;
  padding-bottom: 25px;

  @media ${({ theme }) => theme.devices.xlarge} {
    font-weight: 300;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 1px;
    text-align: left;
    margin-top: unset;
    padding-bottom: unset;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const BlogPostNavigationInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  align-self: flex-start;
  margin-bottom: 28px;
`;

export const BlogPostNavigationTimeRead = styled(TimeRead)`
  color: ${({ theme }) => theme.palette.light};
  margin: 18px auto 0 auto;
  z-index: 1;

  @media ${({ theme }) => theme.devices.xlarge} {
    margin: 0 10px 0 0;
  }
`;

export const Dot = styled.div`
  align-self: center;
  height: 4px;
  width: 4px;
  background: ${({ theme }) => theme.palette.light};
  border-radius: 50%;
  margin-right: 10px;
`;

export const BlogPostNavigationTagsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 14px;
  z-index: 1;
`;
