import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { useTranslations } from 'settings/i18n';

import { theme } from 'styles/styled';
import { H1 } from '../../../components';

import { BlogPost as BlogPostInterface, BlogPostNavigation } from 'types';
import { formatTag } from 'components/UI/Tags/Tag/Tag';
import useLinkCreator from 'hooks/useLinkCreator';
import useWindowSize from 'hooks/useWindowSize';

import { formatTitle } from 'components/Blog/BlogCard/styled';

import {
  Wrapper,
  HeroBackground,
  HeroContainer,
  HeroTextWrapper,
  TagsWrapper,
  StyledTag,
  TimeRead,
  Title,
  Content,
  Dot,
  BlogPostNavigationSection,
  BlogPostNavigationBackground,
  BlogPostNavigationContainer,
  BlogPostNavigationTitle,
  BlogPostNavigationCard,
  BlogPostNavigationUpNextButton,
  BlogPostNavigationInfo,
  BlogPostNavigationTimeRead,
  BlogPostNavigationTagsWrapper,
} from './styled';

interface Props {
  blogPost: BlogPostInterface;
  blogPostNavigation: BlogPostNavigation;
}

const BlogPost = ({ blogPost, blogPostNavigation }: Props) => {
  const t = useTranslations();

  const createLink = useLinkCreator();
  const { width } = useWindowSize();

  const isMobile = width <= theme.deviceSize.xlarge;

  return (
    <>
      <Wrapper>
        {!isMobile && (
          <HeroContainer>
            <HeroBackground />
            <Img
              style={{ width: '625px', height: '425px' }}
              fluid={
                blogPost.featuredImage.node.localFile.childImageSharp.fluid
              }
              alt={blogPost.featuredImage.node.altText}
            />
            <HeroTextWrapper>
              <TagsWrapper>
                {blogPost.tags.nodes.map(({ name }) => (
                  <StyledTag key={name}>{t(formatTag({ name }))}</StyledTag>
                ))}
              </TagsWrapper>
              <Title as={H1}>{formatTitle(blogPost.title)}</Title>
              <TimeRead>12 min read</TimeRead>
            </HeroTextWrapper>
          </HeroContainer>
        )}
        {isMobile && (
          <HeroContainer>
            <HeroBackground />
            <Img
              style={{
                maxWidth: '290px',
                width: '100%',
                aspectRatio: '1.5 / 1',
                marginBottom: '4px',
                marginTop: '20px',
              }}
              fluid={{
                ...blogPost.featuredImage.node.localFile.childImageSharp.fluid,
                aspectRatio: 1.5,
              }}
              alt={blogPost.featuredImage.node.altText}
            />

            <BlogPostNavigationTagsWrapper>
              {blogPost.tags.nodes.map(({ name }) => (
                <StyledTag key={name}>{t(formatTag({ name }))}</StyledTag>
              ))}
            </BlogPostNavigationTagsWrapper>

            <BlogPostNavigationTimeRead>12 min read</BlogPostNavigationTimeRead>

            <BlogPostNavigationTitle as={H1}>
              {formatTitle(blogPost.title)}
            </BlogPostNavigationTitle>
          </HeroContainer>
        )}
        <Content dangerouslySetInnerHTML={{ __html: blogPost.content }} />
      </Wrapper>

      {!isMobile && blogPostNavigation?.next && (
        <BlogPostNavigationSection>
          <BlogPostNavigationContainer>
            <Img
              style={{ width: 400, height: 261.11 }}
              fluid={{
                ...blogPostNavigation.next.featuredImage.node.localFile
                  .childImageSharp.fluid,
                aspectRatio: 1.53,
              }}
            />
            <BlogPostNavigationCard>
              <BlogPostNavigationUpNextButton
                as={Link}
                to={createLink(`/blog/${blogPostNavigation.next.slug}`)}>
                {t('upNext')}
              </BlogPostNavigationUpNextButton>
              <BlogPostNavigationInfo>
                <BlogPostNavigationTimeRead>
                  12 min read
                </BlogPostNavigationTimeRead>
                <Dot />

                {blogPostNavigation.next.tags.nodes.map(({ name }) => (
                  <StyledTag key={name}>{t(formatTag({ name }))}</StyledTag>
                ))}
              </BlogPostNavigationInfo>
              <BlogPostNavigationTitle>
                {blogPostNavigation.next.title}
              </BlogPostNavigationTitle>
            </BlogPostNavigationCard>
          </BlogPostNavigationContainer>
          <BlogPostNavigationBackground />
        </BlogPostNavigationSection>
      )}

      {isMobile && blogPostNavigation?.next && (
        <BlogPostNavigationSection>
          <BlogPostNavigationContainer>
            <BlogPostNavigationCard>
              <BlogPostNavigationUpNextButton
                as={Link}
                to={createLink(`/blog/${blogPostNavigation.next.slug}`)}>
                {t('upNext')}
              </BlogPostNavigationUpNextButton>

              <Img
                style={{
                  maxWidth: 400,
                  width: '100%',
                  maxHeight: 261.11,
                  height: '100%',
                }}
                fluid={{
                  ...blogPostNavigation.next.featuredImage.node.localFile
                    .childImageSharp.fluid,
                  aspectRatio: 1.67,
                }}
              />

              <BlogPostNavigationTagsWrapper>
                {blogPostNavigation.next.tags.nodes.map(({ name }) => (
                  <StyledTag key={name}>{t(formatTag({ name }))}</StyledTag>
                ))}
              </BlogPostNavigationTagsWrapper>

              <BlogPostNavigationTimeRead>
                12 min read
              </BlogPostNavigationTimeRead>

              <BlogPostNavigationTitle>
                {blogPostNavigation.next.title}
              </BlogPostNavigationTitle>
            </BlogPostNavigationCard>
          </BlogPostNavigationContainer>
          <BlogPostNavigationBackground />
        </BlogPostNavigationSection>
      )}
    </>
  );
};

export default BlogPost;
