import React from 'react';
import { graphql, PageProps } from 'gatsby';

import {
  translationsFactory,
  TranslationResult,
  TranslationsContext,
} from 'settings/i18n';
import { BlogPost as BlogPostInterface, BlogPostNavigation } from 'types';
import SEO from 'components/SEO';

import ClientOnly from 'components/ClientOnly/ClientOnly';
import EducationNavigation from 'components/EducationNavigation';
import Footer from 'components/Footer';
import BlogPost from 'components/Blog/BlogPost';
import menuLinks from 'settings/menuLinks';

const BlogPage = (props: PageProps<TranslationResult, BlogPostNavigation>) => {
  const combinedTranslations = {
    wpPage: {
      id: 'translations',
      translation: props.data?.homepage?.translation,
    },
  };
  const t = translationsFactory(combinedTranslations);

  if (!props.data?.blogPost?.nodes) return null;

  const blogPost = (props.data?.blogPost
    ?.nodes[0] as unknown) as BlogPostInterface;

  return (
    <TranslationsContext.Provider value={t}>
      <SEO metadata={props.data.metadata || undefined} url={menuLinks.blog} />
      <ClientOnly>
        <EducationNavigation layout="minimal" />
        <BlogPost blogPostNavigation={props.pageContext} blogPost={blogPost} />
        <Footer />
      </ClientOnly>
    </TranslationsContext.Provider>
  );
};

export default BlogPage;

export const query = graphql`
  query($locale: String!, $slug: String) {
    metadata: wpPost(
      categories: { nodes: { elemMatch: { slug: { regex: "/blog-/" } } } }
      language: { locale: { eq: $locale } }
      slug: { eq: $slug }
    ) {
      ...PostMetaData
    }

    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
      ...FooterTranslation
      ...AboutTranslation
      ...QITranslation
    }

    blogPost: allWpPost(filter: { slug: { eq: $slug } language: { locale: { eq: $locale }} }) {
      nodes {
        title
        content
        featuredImage {
        node {
          id
          altText
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
        tags {
          nodes {
            name
          }
        }
      }
    }
  }
`;
